import * as React from 'react'
import { Link, graphql } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image"
import SEO from '../../components/SEO';
import Layout from '../../layouts/index';

const Apps = (props) => {
  const apps = props.data.allMarkdownRemark.edges;
  console.log(props.data.allMarkdownRemark.edges);

  return (
    <Layout bodyClass="page-apps">
      <SEO title="Apps" />
      <div className="container">
        <div className="row">
          <header className="col-12">
            <h1>Apps</h1>
          </header>
        </div>
      </div>

      <div className="container pb-6">
        <div className="row">
          {apps.map(edge => (
            <div key={edge.node.frontmatter.path} className="col-12 col-md-6 mb-1">
              <div className="card service service-teaser">
                <div className="card-content">
                  <h2>
                    <Link to={edge.node.frontmatter.path}>{edge.node.frontmatter.title}</Link>
                  </h2>

                  <Link to={edge.node.frontmatter.path}>
                    <GatsbyImage
                      fluid={edge.node.frontmatter.image.childImageSharp.fluid}
                      className="img-fluid mb-2"
                      alt={edge.node.frontmatter.title}
                    />
                  </Link>
                  <p>{edge.node.excerpt}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query AppsQuery {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/apps/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          excerpt(pruneLength: 220)
          frontmatter {
            title
            path
            image {
              childImageSharp {
                fluid(quality: 95, maxWidth: 550) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default Apps;
